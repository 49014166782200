import * as actionTypes from "../action";

const initialState = {
  usersLoader: false,
  usersData: [],
  addUserLoader: false,
  addUserData: [],
  userDetailLoader: false,
  userDetailData: {},
  updateUserLoader: false,
  userUpadteData: {},
  usersCount: {},
  verifyAgentLoading: false,
  billingHistoryData: [],
  totalBillingRecords: 0,
  isLoading: false,
  userDetailDataSubscription: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_LOADING: {
      return { ...state, usersLoader: action.payload };
    }
    case actionTypes.TOTAL_USERS_COUNT: {
      return { ...state, usersCount: action.payload };
    }
    case actionTypes.GET_USERS_DATA: {
      return { ...state, usersData: action.payload };
    }
    case actionTypes.ADD_USER_LOADING: {
      return { ...state, addUserLoader: action.payload };
    }
    case actionTypes.ADD_USER_DATA: {
      return { ...state, addUserData: action.payload };
    }
    case actionTypes.GET_USER_DETAIL_LOADING:
      return { ...state, userDetailLoader: action.payload };

    case actionTypes.GET_USER_DETAIL_DATA:
      return {
        ...state,
        userDetailData: action.payload,
      };

    case actionTypes.GET_BILLING_HISTORY_LOADING:
      return { ...state, isLoading: action.payload };

    case actionTypes.GET_BILLING_HISTORY_DATA:
      return { ...state, billingHistoryData: action.payload };

    case actionTypes.DELETE_USER_LOADING:
      return { ...state, deleteUserLoader: action.payload };

    case actionTypes.DELETE_USER_ID:
      return {
        ...state,
        usersData: [
          ...state.usersData.filter((data) => data._id !== action.payload),
        ],
      };
    case actionTypes.VERIFY_AGENT_ID:
      return {
        ...state,
        usersData: state.usersData.map((data) =>
          data._id == action.payload ? { ...data, roles: "agent" } : data
        ),
      };
    case actionTypes.VERIFY_AGENT_LOADING:
      return { ...state, verifyAgentLoading: action.payload };
    case actionTypes.UPDATE_USER_LOADING:
      return { ...state, updateUserLoader: action.payload };
    default:
      return state;
  }
};

export default userReducer;
